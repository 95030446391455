import * as React from "react"
import { graphql } from 'gatsby'

import Seo from "../components/seo"
import AnimationWrapper from "../components/animationWrapper"
import Header from "../components/header"
import Container from "../components/container"
import TextSection from "../components/textSection"
import Layout from "../components/layout"

const NotFoundPage = ( {data, location} ) => {

  console.log('data', data);

  // set meta description values
  const metaDescription = `Czamaninek ➤ ${data.page.frontmatter.title} ✔️ Ekologiczne materiały budowlane ✔️ Polski producent ⭐ Sprawdź!`;

  return (

    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} description={metaDescription} />

      <AnimationWrapper>
        <Header data={data.page.frontmatter.header} slug={data.page.frontmatter.slug} />
      </AnimationWrapper>

      <Container>
        <AnimationWrapper>
          <TextSection className="description-medium" data={data.page.frontmatter.text_section_main} />
        </AnimationWrapper>
      </Container>

    </Layout>
  );
}

export const query = graphql`
  query{
    page: mdx(frontmatter: { slug: { eq: "404-not-found" }, type: { eq: "page" } }) {
      frontmatter{
        title
        meta_description
        slug
        header{
          title
          breadcrumbs {
            title
            url
          }
          overlay
          image {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
        }
        text_section_main{
          active
          title
          title_visible
          # icon{
          #   childImageSharp {
          #     gatsbyImageData(
          #       transformOptions: {fit: COVER},
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
          align
          text
          button_text
          button_url
          button_style
        }
      }
    }
  }
`

export default NotFoundPage
